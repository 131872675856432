import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";
import { HexColorPicker } from "react-colorful";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import _Campaigns from "helpers/Campaigns";
import _CampaignCategories from "helpers/CampaignCategories";
import _Products from "helpers/Products";
import _Prize from "helpers/Prize";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [campaignCategories, setCampaignCategories] = useState(null);
  const [products, setProdcuts] = useState(null);
  const [prize, setPrize] = useState(null);
  const [Files, setFiles] = useState([]);
  const [color, setColor] = useState("#aabbcc");

  const formOpen = useSelector(state => state.crudTbCampaigns.formOpen);
  const CampaignsList = useSelector(state => state.crudTbCampaigns.dataTable);
  const formValues = useSelector(state => state.crudTbCampaigns.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();
  useEffect(() => {
    if (formValues != null) {
      const index = CampaignsList.findIndex(item => item._id == formValues._id);
      setIsEdit(true);
      setInitialFormData(CampaignsList[index]);
      setIsActiveCheck(CampaignsList[index].IsActive);
      setColor(CampaignsList[index].counterBackgroundColor);
    } else {
      setIsEdit(false);
    }
    fetchData();
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const fetchData = async () => {
    setisLoading(true);
    const response =
      await _CampaignCategories.Get_CampaignCategories_Dropdown();
    if (response.IsSuccess === true && response.Status_Code === 200) {
      let data = response.Data;
      setCampaignCategories(data);
    } else {
      console.log(response.message);
      setisLoading(false);
      dispatch(setPopupMessageValues(response.message, branch));
    }
    const response2 = await _Products.Get_Products_Dropdown();
    if (response2.IsSuccess === true && response2.Status_Code === 200) {
      let data = response2.Data;
      setProdcuts(data);
    } else {
      console.log(response2.message);
      setisLoading(false);
      dispatch(setPopupMessageValues(response2.message, branch));
    }
    const response3 = await _Prize.Get_Prize_Dropdown();
    if (response3.IsSuccess === true && response3.Status_Code === 200) {
      let data = response3.Data;
      setPrize(data);
    } else {
      console.log(response3.message);
      setisLoading(false);
      dispatch(setPopupMessageValues(response3.message, branch));
    }
    setisLoading(false);
  };

  const rendercampaignCategories = () =>
    campaignCategories.map((item, index) => (
      <option key={index} value={item._id}>
        {item.name}
      </option>
    ));

  const renderproducts = () =>
    products.map((item, index) => (
      <option key={index} value={item._id}>
        {item.name + " - AED " + item.price}
      </option>
    ));

  const renderprize = () =>
    prize.map((item, index) => (
      <option key={index} value={item._id}>
        {item.name}
      </option>
    ));

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadFiles = (files, previewUrl) => {
    if (files.length > 0 && files.length < 2) {
      files.map(async file => {
        const fileSize = files.size;
        if (fileSize > 2097152) {
          alert(`Maximum file size is 2 Mb.`);
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          await convertBase64(file, result => {
            const imagesArray = Files;
            const newItem = {
              Name: file.name,
              fileType: file.type,
              File: result,
              previewUrl: previewUrl,
            };
            imagesArray.push(newItem);
            setFiles(imagesArray);
          });
        } else {
          alert(`Only Images are allowed.`);
        }
      });
    } else {
      alert(`Only one image is allowed.`);
    }
  };

  const handleFileEvent = e => {
    // const chosenFiles = Array.prototype.slice.call(e.target.files);
    // dispatch(setFiles(null, branch));
    // handleUploadFiles(chosenFiles);

    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setFiles([]);
    const file = e.target.files[0];
    const reader = new FileReader();
    let previewUrl;
    reader.onload = () => {
      previewUrl = reader.result;
      handleUploadFiles(chosenFiles, previewUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: isEdit ? InitialFormData.name : "",
      description: isEdit ? InitialFormData.description : "",
      campaignCategoryId: isEdit ? InitialFormData.campaignCategoryId : "",
      // prize: isEdit ? InitialFormData.prize : "",
      drawType: isEdit ? InitialFormData.drawType : "",
      drawDateTime: isEdit ? InitialFormData.drawDateTime : "",
      // counterBackgroundColor: isEdit
      //   ? InitialFormData.counterBackgroundColor
      //   : "",
      productId: isEdit ? InitialFormData.productId : "",
      prizeId: isEdit ? InitialFormData.prizeId : "",
      // IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name."),
      description: Yup.string().required("Please Enter Description."),
      campaignCategoryId: Yup.string().required(
        "Please Enter campaignCategoryId."
      ),
      // prize: Yup.string().required("Please Enter prize."),
      drawType: Yup.string().required("Please Enter drawType."),
      // counterBackgroundColor: Yup.string().required(
      //   "Please Enter counter Background Color."
      // ),
      drawDateTime: Yup.date().required("Please Enter drawDateTime."),
      productId: Yup.string().required("Please Enter productId."),
      prizeId: Yup.string().required("Please Enter prizeId."),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          _id: InitialFormData._id,
          name: values.name,
          description: values.description,
          campaignCategoryId: values.campaignCategoryId,
          images: Files,
          drawType: values.drawType,
          drawDateTime: values.drawDateTime,
          counterBackgroundColor: color,
          productId: values.productId,
          prizeId: values.prizeId,
          IsActive: IsActiveCheck,
          User: user.id,
        };
        const response = await _Campaigns.Edit_Campaigns(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        } else {
          console.log(response.message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          name: values.name,
          description: values.description,
          campaignCategoryId: values.campaignCategoryId,
          images: Files.length > 0 ? Files : null,
          drawType: values.drawType,
          drawDateTime: values.drawDateTime,
          counterBackgroundColor: color,
          productId: values.productId,
          prizeId: values.prizeId,
          IsActive: IsActiveCheck,
          User: user.id,
        };
        const response = await _Campaigns.Save_Campaigns(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        } else {
          console.log(response.message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        }
      }
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Campaigns" : "Add Campaigns"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Campaign Name
                </Label>
                <Input
                  name="name"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Description
                </Label>
                <Input
                  name="description"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.description : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>campaign Category
                </Label>
                <Input
                  type="select"
                  name="campaignCategoryId"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.campaignCategoryId || ""}
                  invalid={
                    validation.touched.campaignCategoryId &&
                    validation.errors.campaignCategoryId
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {campaignCategories == null || campaignCategories == undefined
                    ? ""
                    : rendercampaignCategories()}
                </Input>
                {validation.touched.campaignCategoryId &&
                validation.errors.campaignCategoryId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.campaignCategoryId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Image
                </Label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileEvent}
                  id="fileUpload"
                  multiple={false}
                  // disabled={IsTicketClose}
                  accept="image/jpeg, image/png,"
                />
              </div>
            </Col>
            {/* <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Background Color (e.g.
                  #F023AC)
                </Label>
                <Input
                  name="counterBackgroundColor"
                  type="text"
                  maxLength={7}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.counterBackgroundColor || ""}
                  invalid={
                    validation.touched.counterBackgroundColor &&
                    validation.errors.counterBackgroundColor
                      ? true
                      : false
                  }
                />
                {validation.touched.counterBackgroundColor &&
                validation.errors.counterBackgroundColor ? (
                  <FormFeedback type="invalid">
                    {validation.errors.counterBackgroundColor}
                  </FormFeedback>
                ) : null}
              </div>
            </Col> */}
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>draw Type
                </Label>
                <Input
                  name="drawType"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.drawType || ""}
                  invalid={
                    validation.touched.drawType && validation.errors.drawType
                      ? true
                      : false
                  }
                />
                {validation.touched.drawType && validation.errors.drawType ? (
                  <FormFeedback type="invalid">
                    {validation.errors.drawType}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>draw DateTime
                </Label>
                <Input
                  name="drawDateTime"
                  type="datetime-local"
                  // maxLength={255}
                  // onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.drawDateTime || ""}
                  invalid={
                    validation.touched.drawDateTime &&
                    validation.errors.drawDateTime
                      ? true
                      : false
                  }
                />
                {validation.touched.drawDateTime &&
                validation.errors.drawDateTime ? (
                  <FormFeedback type="invalid">
                    {validation.errors.drawDateTime}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>product
                </Label>
                <Input
                  type="select"
                  name="productId"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.productId || ""}
                  invalid={
                    validation.touched.productId && validation.errors.productId
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {products == null || products == undefined
                    ? ""
                    : renderproducts()}
                </Input>
                {validation.touched.productId && validation.errors.productId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.productId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>prize
                </Label>
                <Input
                  type="select"
                  name="prizeId"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.prizeId || ""}
                  invalid={
                    validation.touched.prizeId && validation.errors.prizeId
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {prize == null || prize == undefined ? "" : renderprize()}
                </Input>
                {validation.touched.prizeId && validation.errors.prizeId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.prizeId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Background Color
                  <span>{` (${color})`}</span>
                </Label>
                <HexColorPicker
                  style={{ height: 150, width: 300 }}
                  color={color}
                  onChange={setColor}
                />
              </div>
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
