import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import _HomeBanner from "helpers/HomeBanner";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";
import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
const HomeBanner = props => {
  //meta title
  const dispatch = useDispatch();
  document.title = "Winzco - Home Banner";
  const branch = "crudTbHomeBanner";
  const [isLoading, setisLoading] = useState(false);
  const [Files, setFiles] = useState([]);
  const [image, setImage] = useState([]);

  const popupMessage = useSelector(
    state => state.crudTbHomeBanner.popupMessage
  );
  const user = useSelector(state => state.Login.User);

  const initialData = async () => {
    try {
      setisLoading(true);
      setFiles([]);
      const response = await _HomeBanner.Get_Home_Banner();
      if (
        response.IsSuccess === true &&
        response.Status_Code === 200 &&
        response.Data.length > 0
      ) {
        setImage(response.Data[0].imageURL[0]);
        setisLoading(false);
      } else {
        setImage([]);
        setisLoading(false);
      }
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  React.useEffect(() => {
    initialData();
  }, []);

  const handleSave = async () => {
    setisLoading(true);
    if (Files.length > 0) {
      let body = {
        name: "home_banner",
        images: Files,
        User: user.id,
      };
      const response = await _HomeBanner.Save_Home_Banner(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        initialData();
        document.getElementById("fileUpload").value = null;
        setisLoading(false);
        dispatch(setPopupMessageValues(response.message, branch));
      } else {
        console.log(response.message);
        setisLoading(false);
        document.getElementById("fileUpload").value = null;
        dispatch(setPopupMessageValues(response.message, branch));
      }
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues("Select an image first.", branch));
    }
  };

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadFiles = (files, previewUrl) => {
    if (files.length > 0 && files.length < 2) {
      files.map(async file => {
        const fileSize = files.size;
        console.log(file);
        if (fileSize > 2097152) {
          alert(`Maximum file size is 2 Mb.`);
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          await convertBase64(file, result => {
            const imagesArray = Files;
            const newItem = {
              Name: file.name,
              fileType: file.type,
              File: result,
              previewUrl: previewUrl,
            };
            imagesArray.push(newItem);
            setFiles(imagesArray);
          });
        } else {
          alert(`Only Images are allowed.`);
        }
      });
    } else {
      alert(`Only one image is allowed.`);
    }
  };

  const handleFileEvent = e => {
    // const chosenFiles = Array.prototype.slice.call(e.target.files);
    // dispatch(setFiles(null, branch));
    // handleUploadFiles(chosenFiles);

    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setFiles([]);
    const file = e.target.files[0];
    const reader = new FileReader();
    let previewUrl;
    reader.onload = () => {
      previewUrl = reader.result;
      handleUploadFiles(chosenFiles, previewUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("HomeBanner")}
          />

          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Home Banner Image (342
                  x 192)
                </Label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileEvent}
                  id="fileUpload"
                  multiple={false}
                  // disabled={IsTicketClose}
                  accept="image/jpeg, image/png,"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-12 text-start">
              <button
                onClick={handleSave}
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-6 text-start">
              {image.length > 0 ? (
                <img
                  className=" header-profile-user"
                  src={image}
                  alt="img"
                  style={{
                    margin: 3,
                    border: "1px solid black",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                "No image found!"
              )}
            </Col>
          </Row>
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(HomeBanner);
