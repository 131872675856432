import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _Users from "helpers/Users";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const formOpen = useSelector(state => state.crudTbUsers.formOpen);
  const UsersList = useSelector(state => state.crudTbUsers.dataTable);
  const formValues = useSelector(state => state.crudTbUsers.formValues);
  const user = useSelector(state => state.Login.User);

  useEffect(() => {
    if (formValues != null) {
      const index = UsersList.findIndex(item => item._id == formValues._id);
      setIsEdit(true);
      setInitialFormData(UsersList[index]);
      setIsActiveCheck(UsersList[index].IsActive);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: isEdit ? InitialFormData.firstName : "",
      lastName: isEdit ? InitialFormData.lastName : "",
      emailAddress: isEdit ? InitialFormData.emailAddress : "",
      password: isEdit ? InitialFormData.password : "",
      country: isEdit ? InitialFormData.country : "",
      currency: isEdit ? InitialFormData.currency : "",
      userGroup: isEdit ? InitialFormData.userGroup : "",
      contact: isEdit ? InitialFormData.contact : "",
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      emailAddress: Yup.string().required("Please Enter email"),
      password: Yup.string().required("Please Enter password"),
      country: Yup.string().required("Please Enter country"),
      currency: Yup.string().required("Please Enter currency"),
      userGroup: Yup.string().required("Please Enter user group"),
      contact: Yup.string().required("Please Enter contact"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          _id: InitialFormData._id,
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress,
          password: values.password,
          country: values.country,
          currency: values.currency,
          userGroup: values.userGroup,
          contact: values.contact,
          IsActive: IsActiveCheck,
          User: user.id,
        };
        const response = await _Users.Edit_User(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        } else {
          console.log(response.message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress,
          password: values.password,
          country: values.country,
          currency: values.currency,
          userGroup: values.userGroup,
          contact: values.contact,
          IsActive: IsActiveCheck,
          User: user.id,
        };
        const response = await _Users.Save_User(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        } else {
          console.log(response.message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        }
      }
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit User" : "Add User"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>First Name
                </Label>
                <Input
                  name="firstName"
                  type="text"
                  maxLength={255}
                  // onKeyPressCapture={e => _validation.alphaNumeric(e)}fsf
                  // value={isEdit ? InitialFormData.firstName : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName
                      ? true
                      : false
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Last Name
                </Label>
                <Input
                  name="lastName"
                  type="text"
                  maxLength={255}
                  // onKeyPressCapture={e => _validation.alphaNumeric(e)}fsf
                  // value={isEdit ? InitialFormData.lastName : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ""}
                  invalid={
                    validation.touched.lastName && validation.errors.lastName
                      ? true
                      : false
                  }
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Email Address
                </Label>
                <Input
                  name="emailAddress"
                  type="email"
                  maxLength={255}
                  // value={isEdit ? InitialFormData.emailAddress : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailAddress || ""}
                  invalid={
                    validation.touched.emailAddress &&
                    validation.errors.emailAddress
                      ? true
                      : false
                  }
                />
                {validation.touched.emailAddress &&
                validation.errors.emailAddress ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailAddress}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Password
                </Label>
                <Input
                  name="password"
                  type="text"
                  maxLength={255}
                  // value={isEdit ? InitialFormData.password : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Country
                </Label>
                <Input
                  name="country"
                  type="text"
                  maxLength={255}
                  // value={isEdit ? InitialFormData.country : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.country || ""}
                  invalid={
                    validation.touched.country && validation.errors.country
                      ? true
                      : false
                  }
                />
                {validation.touched.country && validation.errors.country ? (
                  <FormFeedback type="invalid">
                    {validation.errors.country}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Contact
                </Label>
                <Input
                  name="contact"
                  type="text"
                  maxLength={255}
                  // value={isEdit ? InitialFormData.contact : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contact || ""}
                  invalid={
                    validation.touched.contact && validation.errors.contact
                      ? true
                      : false
                  }
                />
                {validation.touched.contact && validation.errors.contact ? (
                  <FormFeedback type="invalid">
                    {validation.errors.contact}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Currency
                </Label>
                <Input
                  type="select"
                  name="currency"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.currency || ""}
                  invalid={
                    validation.touched.currency && validation.errors.currency
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  <option value={"USD"}>USD</option>
                  <option value={"AED"}>AED</option>
                </Input>
                {validation.touched.currency && validation.errors.currency ? (
                  <FormFeedback type="invalid">
                    {validation.errors.currency}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>User Group
                </Label>
                <Input
                  type="select"
                  name="userGroup"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.userGroup || ""}
                  invalid={
                    validation.touched.userGroup && validation.errors.userGroup
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  <option value={"mobileApp"}>Mobile App</option>
                  <option value={"admin"}>Admin Panel</option>
                </Input>
                {validation.touched.userGroup && validation.errors.userGroup ? (
                  <FormFeedback type="invalid">
                    {validation.errors.userGroup}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
